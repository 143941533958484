
import './App.css';

import LandingPage from './components/page/page'



function App() {




  
  return (


    <div className="App">

      <LandingPage></LandingPage>
    </div>
  );
}

export default App;
