import { Scrollchor } from 'react-scrollchor';
import Images from '../Images/Images';
const LandingPage = (props) => (
<div>
      <header>
        <img src="img/logo.png" />
        <div>
          <Scrollchor to="#biographie" className="nav-link">Biographie</Scrollchor>
          <span> - </span>
          <Scrollchor to="#galerie" className="nav-link">Galerie</Scrollchor>
        </div>
      </header>

      <section id="biographie">
      <img src="img/auto-portrait.png" />
          <p><span class="Style3"><strong>"L´art psychosymbolique de Béryl Becker entraine dans une marche vers la lumière"</strong></span></p>
          <p><span class="Style3">                        
                          Jean Chevalier<br/>
                          <br/>
                          A huit ans je choisissais entre apprendre la peinture ou la musique.<br/>
                          Sans  hésitation je prends des lecons de peinture car je savais dessiner  d´instinct mais je n´avais plus d´inspiration et me perdais dans des  figurines de mode.<br/>
                          Il était temps que je suive un chemin qui me convienne! Et quel chemin, celui des symbolistes en particuliers des nabis.<br/>
                          <br/>
                          <strong>Propos sur ma peinture</strong><br/>
                          <br/>
                          Petite  fille très tournée vers les arts, qui connaissait par coeur le musée du  Louvre grâce à ma tante Marguerite; j´allais avec elle tous les jeudis  au cours du Louvre car elle préparait une thèse. J´aimais beaucoup ces  conférences.<br/>
                          Le «&nbsp;Cheval blanc&nbsp;» de Gauguin était mon chéri, aussi, quand on me  présente à Madame <strong>Paul Sérusier</strong> ce fut pour moi l´enchantement de  travailler dans l´atelier de son mari.<br/>
                          La  «&nbsp; grande vierge à l´enfant&nbsp;» hiératique dont l´auréole se continue  dans la ligne du décolleté m´as fait apprendre tout de suite l´harmonie  des formes et des couleurs et l´importance de chaque trait non laissé  au hazard.<br/>
                          <br/>
                          J´exultais de joie sereine dans ce décor inhabituel, car a huit ans  on a les mêmes sensations qu´un adulte et très vite l´inspiration était  jaillissante.<br/>
                          Au cours, je peingnais des bouquets ou natures mortes  d´après modèle mais après la classe, à la maison, je recevais «&nbsp; la  vierge à l´enfant endormi&nbsp;», «&nbsp; Sainte Cécile&nbsp;» , «&nbsp;Enthéléchie&nbsp;», «&nbsp;  Danphis et Cloe&nbsp;», «&nbsp; Saint- Jean- Baptiste baptisant le Christ&nbsp;»,  «&nbsp;Paul et Virginie&nbsp;», «&nbsp;la Madeleine au pied de Jésus&nbsp;», etc....<br/>
                          Madame Sérusier m´embrassait après chaque tableau et les montrait avec fièreté aux critiques d´art.<br/>
                          Elle nous enseignait les théories de son mari (professeur et théoricien des nabis) que Gauguin a su si bien exploiter. <br/>
                          Nous composions les peintures selon la règle d´or. Tous les points  importants étaient reliés aux autres par des figures géométriques  invisibles mais présentes pour l´équilibre de la composition,  permettant de voir le tableau en plusieurs sens, sans donner  l´impression de chute malencontreuse.<br/>
                          Tout cela me passionnait: j´étais très influencée à la grande joie de mon professeur.<br/>
                          L´harmonie des couleurs est créée par le mélange des tons purs rehaussés par l´écrin des tons rompus.<br/>
                          Nous  apprenons la gamme des froids avec le jaune citron et le carmin et la  gamme des chauds avec le carmin et défense de mélanger les tons froids  aux tons chauds à cause des mauvaises réactions chimiques qui terniront  les couleurs dans cinquante ans.<br/>
                          J´étais très sensible à cela, d´autant plus que je préférais de  beaucoup les «&nbsp;primitifs italiens&nbsp;» qui suivaient ce procédé aux  peintures de Rembrandt qui l´ignorait.<br/>
                          Madame  Sérusier avait quatre vingt ans et moi une dizaine d´année...mais nous  nous sentions à l´aise entre peintres, sans barrière d´âge, car elle  était très gaie.<br/>
                          Je trouvais, au contraire de celle de son mari, son oeuvre médiocre aussi je ne me sentais pas écrasée!<br/>
                          Les années heureuses passaient et les vacances dans sa maison de Chateauneuf-du-Faou...<br/>
                          Je couchais dans l´atelier, plein de grands tableaux représentant des nymphes et des korrigans grandeur nature.<br/>
                          La bougie les animait et je dormais plutôt la tête sous les draps tellement j´avais peur...<br/>
                          Le  soleil du matin et la cueillette quotidienne des framboises dissipaient  les tourments de l´imagination et j´attendais le jeune homme!<br/>
                          Celui du roman des «&nbsp;Demoiselles aux chapeaux verts&nbsp;» car Madame  Sérusier vivait avec ses deux soeurs, s´en moquait avec humour si bien  que j´étais l´héroine du livre.<br/>
                          Je dois être présentée à Maurice Denis, il meurt peu avant.<br/>
                          C´est la guerre, le bouleversement, je ne vais plus revoir mon professeur qui meurt en Bretagne.<br/>
                          <br/>
                          Je  passe le concours à l´Ecole d´art Aplliqué à l´Industrie, rue Duperré,  recue première, pas d´hésitation à transformer un art en métier.<br/>
                          Enfin la première école ou je travaille avec plaisir, sans fatigue....<br/>
                          J´y apprend la publicité, l´illustration, la laque, la perspective et toutes les techniques du dessin.<br/>
                          Ma tante me confiant deux super-livres: «&nbsp;l´évangile selon Saint Jean «&nbsp; et «&nbsp;les fleurs du mal&nbsp;»<br/>
                          à illustrer, je trouve beaucoup de très beaux livres numérotés, confiés  par des libraires pour en faire des exemplaires uniques, illustrés et  reliés. A vingt ans la «&nbsp;Colombe&nbsp;» m´édite les Contes de Samain...<br/>
                          <br/>
                          Les enfants viennent et je peins des paysages. Ma vie me révolte, me fait devenir «&nbsp;fauve&nbsp;»...<br/>
                          <br/>
                          Et puis, cette paralysie guérie en une nuit, grâce à Dieu, qui a  donné naissance à ma peinture psychosymbolique actuelle au bout de huit  jours!<br/>
                          <br/>
                          Changement de prénom, de place, ouverture sur une nouvelle vie d´émerveillement et de joie...<br/>
                          <br/>
                          Mon «&nbsp;moi intérieur&nbsp;» jusqu´alors caché, s´exprime sur ces nouveaux  tableaux que personne n´admet. Ma mère me demande de refaire du  figuratif. Je lui dis : «&nbsp;je ne peux revenir en arrière, ou tu me suis,  ou tu est contre moi&nbsp;». Elle m´aimait tant que sa sensibilité s´est  ouverte à ma peinture, et devient mon supporter fanatique.<br/>
                          Heureusement, je n´avais pas besoin d´encouragement, il fallait que je  peigne les visions symboliques que je voyais pour qu´elles  disparaissent et se retrouvent sur la toile pour mon repos.<br/>
                          Naturellement,  ni abstraite, ni figurative je suis celle qu´on ne sait ou mettre dans  les salons et pourtant je suis sociétaire aux Indépendants au bout de  deux ans, sans relation!<br/>
                          <br/>
                          J´aurais surement continué dans la solitude, mon bonhomme de chemin  si je n´avais écouté Jean Chevalier lors d´un séminaire à Chantilly sur  le psychosymbolisme...<br/>
                          Instinctivement, je sentais qu´il enseignait à la Sorbonne ce que je faisais depuis des années!<br/>
                          Je lui présente mes diapositives, il les passe en les commentant et  quinze jours après faisait une réunion chez moi: c´était la délivrance,  la communication avec les autres que j´attendais patiemment.<br/>
                          <br/>
                          Je vends mes premiers tableaux, mes clients deviennent mes amis et cela continue...<br/>
                          Libérée  de mon égocentrisme, je peux exprimer le «&nbsp;moi intérieur&nbsp;» des autres,  grâce à une connivence de sentiments qui donne naissance au portrait  psychologique.<br/>
                          Ma peinture est un fragment d´une vision symbolique panoramique, en mouvements.<br/>
                          Mon travail est de cadrer la partie essentielle, qu´elle se compose bien dans le format. Quelques<br/>
                          heures  environ après, je recois le texte d´application, ensuite je vérifie  dans le «&nbsp;dictionnaire des symboles&nbsp;» de Jean Chevalier qui me rassure  toujours sur la véracité; je mûris l´oeuvre jusqu´à temps d´être  obsédée et je peins d´un jet à trois reprises, le temps que la peinture  sèche pour les superpositions.<br/>
                          <br/>
                          Le <strong>BLEU</strong> exprimant la sagesse, spiritualité<br/>
                          Le <strong>ROUGE</strong> l´amour heureux ou malheureux s´il est sombre, couleur de sang coagulé<br/>
                          Le <strong>NOIR</strong> le gouffre, la mort<br/>
                          Le <strong>VERT</strong> la nature<br/>
                          Le <strong>JAUNE</strong> la richesse intérieure<br/>
                          Le <strong>BLANC</strong> la lumière<br/>
                          Le <strong>GRIS</strong> la tristesse<br/>
                          Le <strong>ROSE</strong> la tendresse<br/>
                          <br/>
                          Avec  cette palette simple je peux exprimer toute la gamme des sentiments et  faire passer les idées par ce langage universel dans un tableau  toutefois très construit car l´enseignement recu est toujours dans mon  subconscient sans que j´ai besoin d´y recourir volontairement.<br/>
                          On ne peut pas dire, de ce fait, que ce soit une peinture intellectuelle, ce que je ne suis pas.<br/>
                          Sensuelle, sensitive, portée par la spiritualité, me concerneraient mieux.<br/>
                          <br/>
                          En  parallèle, attirée par la matière parfaite de la porcelaine, si  agréable au toucher, je me suis lancée après une lecon d´une ouvrière  de Limoges dans la décoration directe sur l´objet.<br/>
                          Trois ans de recherches personnelles m´ont donné un style qui était  beaucoup trop en avance il y a trente ans puisqu´il est encore  d´avant-garde. Il n´as pas été lancé car je pensais ne plus pouvoir  continuer à cause de la toxicité de cet art.<br/>
                          Heureusement je viens d´avoir une recette non dangereuse qui va me permettre de poursuivre.<br/>
                          Ma  technique ne concerne que des pièces uniques car j´ai une extrème  facilité de conception et de réalisation, les prix étant modiques  volontairement pour être accessible à tous.<br/>
                          Réaction des gens: «&nbsp;cela n´est pas possible que ce soit peint à la main pour ce prix là&nbsp;»...<br/>
                          <br/>
                                                  </span><br/>                      
                        </p>
    </section>

    <div id="galerie">
      <Images></Images>
    </div>



</div>



);

export default LandingPage;