
import React,{Component} from 'react';

class Images extends Component{
  
  render(){

    let images = Array.from({ length: 65 } , (v, k) => ({ src: "img/berylbecker_" + k + ".JPG"}))
    return (
<div>
{images.map((src, index) => {
        return (
          <div className="image-block-small" key={index} style={{  boxSizing: "border-box", padding: "50px 10px"}} >
            <img loading="lazy" src={src.src} alt={src.src} style={{ maxWidth: "60vH", width: "100%", border: "15px solid black", padding: "20px" ,boxSizing: "border-box"}}/>
          </div>
          )
        })
      } 
</div>

    )
  }
}

export default Images;